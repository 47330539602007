@if (collectionTree) {
  <div class="query-collections__item">
    <div class="query-collections__item-details-wrapper">
      <div
        class="query-collections__item-details"
        data-test-id="collection-collapse"
        (click)="toggleContent()"
        >
        <div class="query-collections__item-title">
          @if (showContent) {
            <span class="query-title-icon"
              ><app-icon name="folder-minus"></app-icon
            ></span>
          }
          @if (!showContent) {
            <span class="query-title-icon"
              ><app-icon name="folder-plus"></app-icon
            ></span>
          }
          <span>{{ collectionTree.title }}</span>
        </div>
        <div class="query-collections__item-meta">
          {{ 'COLLECTIONS_NUMBER_OF_REQUESTS' | translate }}
          {{ getQueryCount(collectionTree) }}
        </div>
        <!-- <div class="query-collections__item-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi velit voluptate, delectus possimus corrupti adipisci iusto itaque cum dignissimos sed quae eaque magni harum ratione laborum similique excepturi voluptatibus molestiae?</div> -->
      </div>
      @if (loggedIn && (collectionTree.storageType === 'api')) {
        <span
          class="query-collections__item-synced-icon"
          [title]="'CLOUD_SYNC_TEXT' | translate"
          >
          <app-icon name="cloud"></app-icon>
        </span>
      }
      <div class="query-collections__item-right">
        <span class="query-collections__item-icon">
          <button
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="collectionItemMenu"
            type="button"
            class="query-collections__item-icon__button"
            >
            <app-icon name="more-horizontal"></app-icon>
          </button>
        </span>
        <nz-dropdown-menu #collectionItemMenu="nzDropdownMenu">
          <ul nz-menu>
            @if (loggedIn && !(collectionTree.storageType === 'api')) {
              <li
                nz-menu-item
                data-test-id="sync-collection"
                (click)="syncCollection()"
                >
                {{ 'COLLECTIONS_MENU_SYNC' | translate }}
              </li>
            }
            <li
              nz-menu-item
              data-test-id="edit-collection"
              (click)="editCollection()"
              >
              {{ 'COLLECTIONS_MENU_EDIT' | translate }}
            </li>
            <li
              nz-menu-item
              data-test-id="delete-collection"
              (click)="deleteCollection()"
              >
              {{ 'COLLECTIONS_MENU_DELETE' | translate }}
            </li>
            <li
              nz-menu-item
              data-test-id="export-collection"
              (click)="exportCollection()"
              >
              {{ 'COLLECTIONS_MENU_EXPORT' | translate }}
            </li>
            <li nz-submenu [nzTitle]="'SORT_BY' | translate">
              <ul>
                <li
                  nz-menu-item
                  data-test-id="sort-queries-a-z"
                  (click)="setQueriesSortBy('a-z')"
                  >
                  {{ 'SORT_BY_A_Z' | translate }}
                </li>
                <li
                  nz-menu-item
                  data-test-id="sort-queries-z-a"
                  (click)="setQueriesSortBy('z-a')"
                  >
                  {{ 'SORT_BY_Z_A' | translate }}
                </li>
                <li
                  nz-menu-item
                  data-test-id="sort-queries-newest"
                  (click)="setQueriesSortBy('newest')"
                  >
                  {{ 'SORT_BY_NEWEST' | translate }}
                </li>
                <li
                  nz-menu-item
                  data-test-id="sort-queries-oldest"
                  (click)="setQueriesSortBy('oldest')"
                  >
                  {{ 'SORT_BY_OLDEST' | translate }}
                </li>
              </ul>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
    <div
      class="query-collections__item-content-wrapper"
      [ngClass]="{ 'query-collections__item-content--hidden': !showContent }"
      >
      <div class="query-collections__item-nested">
        @for (
          subcollectionTree of collectionTree.collections; track trackById($index,
          subcollectionTree)) {
          <app-query-collection-item
            [collectionTree]="subcollectionTree"
            [loggedIn]="loggedIn"
            (selectQueryChange)="selectQueryChange.next($event)"
            (deleteQueryChange)="deleteQueryChange.next($event)"
            (deleteCollectionChange)="deleteCollectionChange.next($event)"
            (editCollectionChange)="editCollectionChange.next($event)"
            (exportCollectionChange)="exportCollectionChange.next($event)"
          ></app-query-collection-item>
        }
      </div>
      <div class="query-collections__item-queries">
        @for (
          query of sortedCollectionQueries(collectionTree.queries, queriesSortBy); track trackById($index,
          query)) {
          <div
            class="query-collections__item-queries-item"
            >
            <span
              class="query-collections__item-queries-item-title"
              data-test-id="query-item-title"
          (click)="
            selectQueryChange.next({
              query: query,
              collectionId: collectionTree.id,
              windowIdInCollection: query.id
            })
          "
              >
              <span class="query-title-icon"
                ><app-icon name="file"></app-icon
              ></span>
              {{ query.windowName }}
              @if (!query.id) {
                <span
                  class="query-title-icon"
                  nz-tooltip
                  [nzTooltipTitle]="deprecatedCollectionQuery"
                  nzTooltipPlacement="bottom"
                  >
                  <app-icon name="alert-triangle"></app-icon>
                </span>
              }
              <ng-template #deprecatedCollectionQuery>
                {{ 'COLLECTIONS_DEPRECATED_QUERY_WARNING' | translate }}
                <a
                  target="_blank"
                  href="https://altairgraphql.dev/docs/tips/deprecated-collection-queries"
                  rel="noopener"
                  >{{ 'LEARN_MORE_TEXT' | translate }}</a
                  >
                </ng-template>
              </span>
              @if (loggedIn && (query.storageType === 'api')) {
                <span
                  class="query-collections__item-queries-item-synced-icon"
                  [title]="'CLOUD_SYNC_TEXT' | translate"
                  >
                  <app-icon name="cloud"></app-icon>
                </span>
              }
              <span class="query-collections__item-queries-item-right">
                <span class="query-collections__item-queries-item-icon">
                  <button
                    type="button"
                    nz-dropdown
                    nzTrigger="click"
                    [nzDropdownMenu]="collectionQueryItemMenu"
                    class="query-collections__item-icon__button"
                    >
                    <app-icon name="more-horizontal"></app-icon>
                  </button>
                </span>
                <nz-dropdown-menu #collectionQueryItemMenu="nzDropdownMenu">
                  <ul nz-menu>
                    @if (loggedIn && (query.storageType === 'api')) {
                      <li
                        nz-menu-item
                        (click)="copyQueryShareLinkChange.emit(query.id)"
                      >
                        {{ 'COLLECTIONS_QUERY_MENU_COPY_SHARE_URL' | translate }}
                    </li>
                      <li
                        nz-menu-item
                        (click)="showQueryRevisionsDialog(query)"
                      >
                        {{ 'COLLECTIONS_QUERY_MENU_SHOW_REVISIONS_DIALOG' | translate }}
                    </li>
                    }
                    <li
                      nz-menu-item
                      data-test-id="delete-query"
                      (click)="deleteQuery(query)"
                      >
                      {{ 'COLLECTIONS_QUERY_MENU_DELETE' | translate }}
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  }
