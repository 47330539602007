<app-dialog
  [showDialog]="showDialog"
  [heading]="'ACCOUNT_TITLE' | translate"
  [subheading]="'ACCOUNT_SUB_TEXT' | translate"
  [showFooter]="false"
  (toggleDialog)="toggleDialogChange.emit($event)"
>
  <div class="account-signin-wrapper">
    @if (!account?.loggedIn) {
      <div class="account-dialog__not-logged-in">
        <div class="account-dialog__not-logged-in__inner">
          <nz-avatar
            nzText="?"
            nzSrc="assets/img/altair_logo.svg"
            nzSize="large"
          ></nz-avatar>

          <h2>Login to get started</h2>
          <p>
            Sign in to manage your plans, teams, synced collections, queries, and get
            access to even more features!
            <a href="https://altairgraphql.dev/docs/cloud/"
              >Learn more <app-icon name="info"
            /></a>
          </p>
          <button class="btn btn--primary" (click)="submitLogin()">
            {{ 'SIGNIN_WITH_GOOGLE' | translate }}
          </button>
        </div>
      </div>
    }

    @if (account?.loggedIn) {
      <div class="app-dialog-section">
        <div class="account-dialog__welcome">
          <nz-avatar
            [nzText]="account?.firstName?.at(0)"
            [nzSrc]="account?.picture"
          ></nz-avatar>
          {{ 'ACCOUNT_HELLO' | translate }}, {{ account?.firstName }}
        </div>
      </div>
      <p>
        <a class="link" target="_blank" (click)="openBillingPage($event)">
          Billing
          <app-icon name="external-link"></app-icon>
        </a>
        @if (account?.plan?.canUpgradePro) {
          <button
            class="btn btn--primary btn--light btn--small"
            (click)="openUpgradeProUrl($event)"
          >
            <app-icon name="sparkles"></app-icon> Upgrade plan
          </button>
        }
      </p>
      <div class="account-dialog__stats">
        <div class="account-dialog__stats-title">
          Plan Stats
          <app-icon
            name="info"
            nz-tooltip
            [nzTooltipTitle]="'ACCOUNT_PLAN_STATS_DESCRIPTION' | translate"
          ></app-icon>
        </div>
        <div>Plan: {{ account?.plan?.id }}</div>
        <div>
          Queries: {{ account?.stats?.queriesCount ?? 0 }} /
          {{ account?.plan?.maxQueriesCount ?? 0 }}
        </div>
        <div>
          Teams: {{ account?.stats?.teamsCount ?? 0 }} /
          {{ account?.plan?.maxTeamsCount ?? 0 }}
        </div>
        <div>Fixed Credits: {{ account?.availableCredits?.fixed ?? 0 }}</div>
        <div>
          Monthly Credits (Pro users only):
          {{ account?.availableCredits?.monthly ?? 0 }}
        </div>
        <button
          class="btn btn--primary btn--light btn--small"
          (click)="buyCredits($event)"
          [disabled]="account?.plan?.canUpgradePro"
        >
          <app-icon name="coins"></app-icon> Buy Credits
          @if (account?.plan?.canUpgradePro) {
            (Pro users only)
          }
        </button>
      </div>
      <button class="btn" (click)="logoutChange.emit()">
        {{ 'LOGOUT_TEXT' | translate }}
      </button>
    }
  </div>
</app-dialog>
