<app-dialog
  [showDialog]="showDialog"
  [heading]="'REQUEST_HANDLERS_TEXT' | translate"
  [subheading]="'REQUEST_HANDLERS_SUBTEXT' | translate"
  (toggleDialog)="toggleDialogChange.emit($event)"
>
  <div class="request-handlers-wrapper">
    <div class="app-dialog-body">
      <div class="request-handler-params-section">
        <div class="request-handler-params-title">
          {{ 'DEFAULT_REQUEST_HANDLER_TITLE' | translate }}
        </div>
        <nz-select
          class="dialog-select"
          [ngModel]="requestHandlerId"
          (ngModelChange)="updateRequestHandlerInfo({ requestHandlerId: $event })"
        >
          @for (handler of requestHandlers; track trackById($index, handler)) {
            <nz-option
              [nzValue]="handler.id"
              [nzLabel]="handler.copyTag || '' | translate"
            ></nz-option>
          }
        </nz-select>
      </div>

      <div class="request-handler-params-section">
        <div class="request-handler-params-title">
          {{ 'REQUEST_HANDLER_PARAMS_TEXT' | translate }}
        </div>
        <div class="request-handler-params-editor-wrapper">
          <app-codemirror
            class="request-handler-params__input"
            [ngModel]="requestHandlerAdditionalParams"
            (ngModelChange)="updateRequestHandlerInfo({ additionalParams: $event })"
            [extensions]="connectionParamsExtensions"
            [showLineNumber]="false"
            [foldGutter]="false"
          ></app-codemirror>
        </div>
      </div>
      <div class="request-handler-params-section">
        <div class="request-handler-params-title">
          {{ 'SUBSCRIPTION_URL_TEXT' | translate }}
        </div>
        <app-x-input
          type="url"
          class="input"
          [ngModel]="subscriptionUrl"
          (ngModelChange)="updateRequestHandlerInfo({ subscriptionUrl: $event })"
          placeholder="ws://example.com/graphql/subscriptions"
        />
      </div>
      <div class="request-handler-params-section">
        <nz-switch
          nzSize="small"
          [ngModel]="subscriptionUseDefaultRequestHandler"
          (ngModelChange)="
            updateRequestHandlerInfo({
              subscriptionUseDefaultRequestHandler: $event
            })
          "
        ></nz-switch>
        {{ 'SUBSCRIPTION_USE_DEFAULT_REQUEST_HANDLER' | translate }}
      </div>

      @if (!subscriptionUseDefaultRequestHandler) {
        <div class="p-10 glassy-border">
          <div class="request-handler-params-section">
            <div class="request-handler-params-title">
              {{ 'SUBSCRIPTION_TYPE_TITLE' | translate }}
            </div>
            <nz-select
              class="dialog-select"
              [ngModel]="selectedSubscriptionRequestHandlerId"
              (ngModelChange)="
                updateRequestHandlerInfo({ subscriptionRequestHandlerId: $event })
              "
            >
              @for (handler of requestHandlers; track trackById($index, handler)) {
                <nz-option
                  [nzValue]="handler.id"
                  [nzLabel]="handler.copyTag || '' | translate"
                ></nz-option>
              }
            </nz-select>
          </div>

          <div class="request-handler-params-section">
            <div class="request-handler-params-title">
              {{ 'SUBSCRIPTION_CONNECTION_PARAMS_TEXT' | translate }}
            </div>
            <div class="request-handler-params-editor-wrapper">
              <app-codemirror
                class="request-handler-params__input"
                [ngModel]="subscriptionConnectionParams"
                (ngModelChange)="
                  updateRequestHandlerInfo({ subscriptionConnectionParams: $event })
                "
                [extensions]="connectionParamsExtensions"
                [showLineNumber]="false"
                [foldGutter]="false"
              ></app-codemirror>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</app-dialog>
