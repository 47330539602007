<div class="ui-form-wrapper">
  <form nz-form nzLayout="vertical">
    @for (item of schemaProperties; track item) {
      <app-schema-form-item
        [item]="item"
        [(data)]="formData[item.key]"
        (dataChange)="onInput($event, item)"
      ></app-schema-form-item>
    }
  </form>
</div>
