<nz-modal
  [nzVisible]="showDialog"
  (nzVisibleChange)="toggleDialog.emit($event)"
  [nzCloseIcon]="'close-circle'"
  [nzTitle]="showHeader ? modalTitle : null"
  [nzContent]="modalContent"
  [nzFooter]="showFooter ? modalFooter : null"
  [nzWidth]="width"
  (nzOnCancel)="toggleDialog.emit()"
>
  <ng-template #modalTitle>
    <div class="app-dialog-header">
      <div class="app-dialog-title">{{ heading }}</div>
      {{ subheading }}
    </div>
  </ng-template>

  <ng-template #modalContent>
    <ng-content></ng-content>
  </ng-template>

  <ng-template #modalFooter>
    @if (showFooter) {
      <div class="app-dialog-footer">
        <button class="btn btn--primary right" (click)="onClickSave($event)">
          {{ 'SAVE_BUTTON' | translate }}
        </button>
      </div>
    }
  </ng-template>
</nz-modal>
