@if (data) {
  <div class="doc-viewer-section">
    <!--Type name and description-->
    <div class="doc-viewer-item doc-viewer-item-field">
      <div class="doc-viewer-item-query-inner">
        {{ data.name }}
        @if (isGraphQLUnion(data)) {
          <span class="doc-viewer-item-type doc-viewer-item-query-type">{{
            'DOCS_UNION_TEXT' | translate
          }}</span>
        }
        @if (isGraphQLInterface(data)) {
          <span class="doc-viewer-item-type doc-viewer-item-query-type">{{
            'DOCS_INTERFACE_TEXT' | translate
          }}</span>
        }
      </div>
      @if (data.description) {
        <div
          class="doc-viewer-item-query-description"
          markdown
          [data]="data.description || ''"
        ></div>
      }
    </div>
  </div>
  @if (getTypeEnumValues(data).length) {
    <div class="doc-viewer-section doc-viewer-enum-values">
      <div class="doc-viewer-section-title">
        {{ 'DOCS_VALUES_TEXT' | translate }}
      </div>
      @for (item of getTypeEnumValues(data); track schemaItemTrackBy($index, item)) {
        @if (!(hideDeprecatedDocItems && item?.isDeprecated)) {
          <div
            class="doc-viewer-item doc-viewer-item-query"
            [ngClass]="{ 'doc-viewer-item--deprecated': item.isDeprecated }"
          >
            <div class="doc-viewer-item-query-inner">
              <span class="doc-viewer-item-field doc-viewer-item-value">
                {{ item.name }}
              </span>
              <span class="doc-viewer-item-type">
                {{ item.value }}
              </span>
              <app-doc-viewer-deprecated
                [isDeprecated]="item.isDeprecated"
                [deprecatedReason]="item.deprecationReason"
              ></app-doc-viewer-deprecated>
            </div>
            @if (item.description) {
              <div
                class="doc-viewer-item-query-description"
                markdown
                [data]="item.description || ''"
              ></div>
            }
          </div>
        }
      }
    </div>
  }
  @if (getSubtypes(data).length) {
    <div class="doc-viewer-section doc-viewer-subtypes">
      <div class="doc-viewer-section-title">
        {{ 'DOCS_SUBTYPES_TEXT' | translate }}
      </div>
      @for (item of getSubtypes(data); track schemaItemTrackBy($index, item)) {
        <div class="doc-viewer-item doc-viewer-item-query">
          <div class="doc-viewer-item-query-inner">
            <span
              class="doc-viewer-item-field doc-viewer-item-value no-link-link"
              (click)="goToType(item.inspect())"
            >
              {{ item.name }}
            </span>
          </div>
          @if (item.description) {
            <div
              class="doc-viewer-item-query-description"
              markdown
              [data]="item.description || ''"
            ></div>
          }
        </div>
      }
    </div>
  }
  @if (getTypeFields(data).length) {
    <div class="doc-viewer-section doc-viewer-fields">
      <div class="doc-viewer-section-title">
        <span>
          {{ 'DOCS_FIELDS_TEXT' | translate }}
        </span>
        <div class="doc-viewer-fields-actions">
          <button
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="docViewerMenu"
            type="button"
            class="btn btn--small"
          >
            <app-icon name="arrow-down-wide-narrow"></app-icon>
            @switch (sortByOption) {
              @case ('a-z') {
                {{ 'SORT_BY_A_Z' | translate }}
              }
              @case ('z-a') {
                {{ 'SORT_BY_Z_A' | translate }}
              }
              @case ('none') {
                {{ 'SORT_BY_NONE' | translate }}
              }
            }
          </button>
          <nz-dropdown-menu #docViewerMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="setSortBy('none')">
                {{ 'SORT_BY_NONE' | translate }}
              </li>
              <li nz-menu-item (click)="setSortBy('a-z')">
                {{ 'SORT_BY_A_Z' | translate }}
              </li>
              <li nz-menu-item (click)="setSortBy('z-a')">
                {{ 'SORT_BY_Z_A' | translate }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      @for (
        item of getTypeFields(data)
          | collectionTransform: sortFieldsTransformer : sortByOption;
        track schemaItemTrackBy($index, item)
      ) {
        @if (!(hideDeprecatedDocItems && item?.isDeprecated)) {
          <div
            class="doc-viewer-item doc-viewer-item-query"
            [ngClass]="{ 'doc-viewer-item--deprecated': item.isDeprecated }"
          >
            <div class="doc-viewer-item-query-inner">
              <span
                class="no-link-link"
                (click)="goToField(item.name, data.name)"
                track-id="gotofield_docs"
              >
                {{ item.name }}
              </span>
              @if (item?.args?.length) {
                (
                @for (
                  arg of item.args;
                  track schemaItemTrackBy($index, arg);
                  let last = $last
                ) {
                  <span
                    [ngClass]="{
                      'doc-viewer-item--deprecated': arg.deprecationReason
                    }"
                  >
                    <span
                      class="doc-viewer-item-field no-link-link"
                      (click)="goToType(arg.type.inspect())"
                    >
                      {{ arg.name }}
                      @if (getDefaultValue(arg)) {
                        <span class="doc-viewer-item-value"
                          >= {{ getDefaultValue(arg) }}</span
                        >
                      }
                    </span>
                    <span
                      class="doc-viewer-item-type no-link-link"
                      (click)="goToType(arg.type.inspect())"
                      >{{ arg.type.inspect() }}</span
                    >
                    @if (!last) {
                      <span>,</span>
                    }
                  </span>
                }
                )
              }
              <span
                class="doc-viewer-item-type doc-viewer-item-query-type no-link-link"
                (click)="goToType(item.type.inspect())"
              >
                {{ item.type.inspect() }}
              </span>
            </div>
            <app-doc-viewer-deprecated
              [isDeprecated]="item.isDeprecated"
              [deprecatedReason]="item.deprecationReason"
            ></app-doc-viewer-deprecated>
            <div
              class="doc-viewer-item-query-description"
              markdown
              [data]="item.description || ''"
            ></div>
            <button
              class="doc-viewer-item-query-add-btn"
              (click)="addToEditor(item.name, data.name)"
              track-id="add_query"
            >
              @if (isRootType(data.name)) {
                {{ 'DOCS_ADD_QUERY_TEXT' | translate }}
              }
              @if (!isRootType(data.name)) {
                {{ 'DOCS_ADD_FRAGMENT_TEXT' | translate }}
              }
            </button>
          </div>
        }
      }
    </div>
  }
  @if (getTypeImplementations(data).length) {
    <div class="doc-viewer-section doc-viewer-implementations">
      <div class="doc-viewer-section-title">
        {{ 'DOCS_IMPLEMENTATIONS_TEXT' | translate }}
      </div>
      @for (
        item of getTypeImplementations(data);
        track schemaItemTrackBy($index, item)
      ) {
        <div class="doc-viewer-item doc-viewer-item-query">
          <div class="doc-viewer-item-query-inner">
            <span
              class="doc-viewer-item-field doc-viewer-item-value no-link-link"
              (click)="goToType(item.inspect())"
            >
              {{ item.name }}
            </span>
          </div>
          <div
            class="doc-viewer-item-query-description"
            markdown
            [data]="item.description || ''"
          ></div>
        </div>
      }
    </div>
  }
  @if (getTypeImplements(data).length) {
    <div class="doc-viewer-section doc-viewer-implements">
      <div class="doc-viewer-section-title">
        {{ 'DOCS_IMPLEMENTS_TEXT' | translate }}
      </div>
      @for (item of getTypeImplements(data); track schemaItemTrackBy($index, item)) {
        <div class="doc-viewer-item doc-viewer-item-query">
          <div class="doc-viewer-item-query-inner">
            <span
              class="doc-viewer-item-field doc-viewer-item-value no-link-link"
              (click)="goToType(item.inspect())"
            >
              {{ item.name }}
            </span>
          </div>
          <div
            class="doc-viewer-item-query-description"
            markdown
            [data]="item.description || ''"
          ></div>
        </div>
      }
    </div>
  }
}
