<form nz-form nzLayout="vertical" [formGroup]="apiKeyForm">
  <nz-form-item>
    <nz-form-label nzRequired nzFor="headerName">Header name</nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="The input is not a valid key!"
    >
      <app-x-input
        nz-input
        formControlName="headerName"
        id="headerName"
        class="input"
        autocomplete="off"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="headerValue">Header value</nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="The input is not a valid value!"
    >
      <app-x-input
        nz-input
        formControlName="headerValue"
        id="headerValue"
        class="input"
        autocomplete="off"
      />
    </nz-form-control>
  </nz-form-item>
</form>
