<app-dialog
  [showDialog]="showDialog"
  [heading]="'REQUEST_EXTENSIONS_TEXT' | translate"
  [subheading]="'REQUEST_EXTENSIONS_SUBTEXT' | translate"
  (toggleDialog)="toggleDialogChange.emit($event)"
>
  <div class="request-extensions-wrapper">
    <app-codemirror
      [ngModel]="data"
      (ngModelChange)="dataChange.emit($event)"
      [extensions]="editorExtensions"
    ></app-codemirror>
  </div>
</app-dialog>
