@if (banner?.enabled) {
  <nz-alert
    nzBanner
    [nzMessage]="message"
    [nzCloseable]="banner?.dismissible"
    (nzOnClose)="onDismiss()"
  ></nz-alert>
}
<ng-template #message>
  <span class="alert-text">
    @if (banner?.icon) {
      <app-icon [name]="banner?.icon"></app-icon>
    }
    {{ banner?.message }}
  </span>
  @if (banner?.actions) {
    @for (action of banner?.actions; track $index) {
      <button
        class="btn btn--primary btn--light"
        (click)="action.handler()"
        track-id="donate"
      >
        {{ action.label }}
      </button>
    }
  }
</ng-template>
