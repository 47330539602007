<div class="url-box">
  <div class="url-box__method-wrapper">
    <button
      type="button"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="httpVerbMenu"
      class="url-box__button url-box__button--method"
      [value]="httpVerb"
      track-id="http_verb"
    >
      {{ httpVerb }}
      <!-- &#9660; -->
    </button>

    <nz-dropdown-menu #httpVerbMenu="nzDropdownMenu">
      <ul nz-menu>
        @for (method of methods; track method) {
          <li nz-menu-item [value]="method" (click)="setVerb(method)">
            {{ method }}
          </li>
        }
      </ul>
    </nz-dropdown-menu>
  </div>
  <div class="url-box__input-container">
    @if (streamState) {
      <div class="url-box__input-prepend">
        <div class="url-box__connection-indicator">
          @if (streamState === 'uncertain') {
            <app-icon
              name="alert-circle"
              class="connection-indicator--uncertain"
              nz-tooltip
              [nzTooltipTitle]="'STREAM_CONNECTION_TRYING' | translate"
              [nzTooltipOverlayStyle]="{ 'white-space': 'nowrap' }"
            ></app-icon>
          }
          @if (streamState === 'connected') {
            <app-icon
              name="check-circle"
              class="connection-indicator--connected"
              nz-tooltip
              [nzTooltipTitle]="'STREAM_CONNECTION_CONNECTED' | translate"
              [nzTooltipOverlayStyle]="{ 'white-space': 'nowrap' }"
            ></app-icon>
          }
        </div>
      </div>
    }
    <app-x-input
      class="url-box__input"
      track-id="set_url"
      placeholder="Enter URL"
      [(ngModel)]="apiUrl"
      (blurChange)="setApiUrl()"
      (submitChange)="setApiUrl()"
    ></app-x-input>
  </div>
  <div class="url-box__input-actions">
    @if (currentCollection) {
      <button
        type="button"
        class="url-box__button"
        [ngClass]="{ 'url-box__button--disabled': !hasUnsavedChanges }"
        (click)="updateQueryInCollectionChange.emit()"
        track-id="update_in_collection"
        nz-tooltip
        [nzTooltipTitle]="'COLLECTION_UPDATE_WINDOW_IN_COLLECTION' | translate"
        nzTooltipPlacement="bottom"
      >
        <app-icon name="save"></app-icon>
      </button>
    }
    <button
      type="button"
      class="url-box__button"
      (click)="addToCollectionChange.emit()"
      track-id="save_to_collection"
      nz-tooltip
      [nzTooltipTitle]="'SAVE_TO_COLLECTION_BUTTON' | translate"
      nzTooltipPlacement="bottom"
    >
      <app-icon name="plus-circle"></app-icon>
    </button>
    <button
      type="button"
      class="url-box__button"
      (click)="exportWindowChange.emit()"
      track-id="export_window"
      nz-tooltip
      [nzTooltipTitle]="'EXPORT_WINDOW_TEXT' | translate"
      nzTooltipPlacement="bottom"
    >
      <app-icon name="file-down"></app-icon>
    </button>
    <button
      type="button"
      class="url-box__button"
      (click)="reloadDocsChange.emit()"
      track-id="reload_docs"
      nz-tooltip
      [nzTooltipTitle]="'RELOAD_DOCS_BUTTON' | translate"
      nzTooltipPlacement="bottom"
    >
      <app-icon name="refresh-ccw"></app-icon>
    </button>
    <button
      type="button"
      class="url-box__button"
      (click)="toggleDocsChange.emit()"
      [ngClass]="{ 'url-box__button--active': showDocs }"
      track-id="show_docs"
    >
      {{ 'DOCS_TEXT' | translate }}
    </button>
  </div>
  <div class="send-request__wrapper">
    @if (queryOperations.length < 2) {
      <button
        class="app-button active-primary send-request__button"
        (click)="!isSubscribed && this.sendRequest.emit()"
        [ngClass]="{ 'url-box__button--disabled': isLoading }"
        track-id="set_request"
      >
        @if (isLoading) {
          <span>{{ 'LOADING_INDICATOR_TEXT' | translate }}</span>
        }
        @if (!isLoading) {
          <span>{{ 'SEND_REQUEST_BUTTON' | translate }}</span>
        }
      </button>
    }
    @if (queryOperations.length > 1) {
      <button
        class="app-button active-primary send-request__button"
        [ngClass]="{ 'url-box__button--disabled': isLoading }"
        track-id="send_request"
        nz-dropdown
        nzTrigger="click"
        nzPlacement="bottomRight"
        [nzDropdownMenu]="queryOperationsMenu"
      >
        @if (isLoading) {
          <span>{{ 'LOADING_INDICATOR_TEXT' | translate }}</span>
        } @else {
          @if (selectedOperation) {
            <span>{{ 'SEND_REQUEST_BUTTON_SHORT' | translate }}</span>
          } @else {
            <span>{{ 'SEND_REQUEST_BUTTON' | translate }}</span>
          }
        }
        @if (selectedOperation === BATCHED_REQUESTS_OPERATION) {
          <span>(Batched)</span>
        } @else if (selectedOperation) {
          <span [title]="selectedOperation"
            >({{ selectedOperation | truncate: 7 }})</span
          >
        }
        <app-icon name="chevron-down"></app-icon>
      </button>
    }
    <nz-dropdown-menu #queryOperationsMenu="nzDropdownMenu">
      <ul nz-menu>
        @for (
          operation of queryOperations;
          track queryOperationTrackBy($index, operation)
        ) {
          <li
            nz-menu-item
            (click)="selectedOperationChange.emit(operation.name?.value)"
          >
            {{ operation.name?.value }}
          </li>
        }
        <li
          nz-menu-item
          (click)="selectedOperationChange.emit(BATCHED_REQUESTS_OPERATION)"
        >
          {{ 'SEND_BATCHED_REQUEST' | translate }}
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
@if (isLoading) {
  <div class="url-box__loader">
    <div class="url-box__loader-inner"></div>
  </div>
}
