@if (item) {
  @if (item.description) {
    <nz-form-label>{{ item.description }}</nz-form-label>
  }
  <nz-form-control>
    <input
      nz-input
      [placeholder]="item.key || ''"
      [(ngModel)]="data"
      [name]="item.key"
      (ngModelChange)="dataChange.next($event)"
      [nzAutocomplete]="auto"
      />
    <nz-autocomplete
      [nzDataSource]="getAutocompleteOptions(item)"
      nzBackfill
      #auto
    ></nz-autocomplete>
  </nz-form-control>
}
