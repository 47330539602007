<div
  class="query-collections-wrapper"
  [ngClass]="{ 'query-collections-wrapper--close': !showCollections }"
>
  @if (showCollections) {
    <div class="query-collections__inner">
      <div class="query-collections__title">
        {{ 'COLLECTIONS_TEXT' | translate }}
      </div>
      <div class="query-collections__actions clearfix">
        <button
          type="button"
          class="btn btn--small"
          data-test-id="import-collection"
          nz-tooltip
          [nzTooltipTitle]="'COLLECTIONS_IMPORT_TEXT' | translate"
          nzTooltipPlacement="bottom"
          (click)="importCollectionsChange.emit()"
        >
          <app-icon name="log-in"></app-icon>
        </button>
        @if (loggedIn) {
          <button
            type="button"
            class="btn btn--small"
            data-test-id="sync-collection"
            nz-tooltip
            [nzTooltipTitle]="'COLLECTION_SYNC_TEXT' | translate"
            nzTooltipPlacement="bottom"
            (click)="syncCollectionsChange.emit()"
          >
            <app-icon name="refresh-ccw"></app-icon>
          </button>
        }
        <div class="query-collections__actions--right">
          <button
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="docViewerMenu"
            type="button"
            class="btn btn--small"
          >
            @switch (sortBy) {
              @case ('a-z') {
                {{ 'SORT_BY_A_Z' | translate }}
              }
              @case ('z-a') {
                {{ 'SORT_BY_Z_A' | translate }}
              }
              @case ('newest') {
                {{ 'SORT_BY_NEWEST' | translate }}
              }
              @case ('oldest') {
                {{ 'SORT_BY_OLDEST' | translate }}
              }
            }
          </button>
          <nz-dropdown-menu #docViewerMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="sortCollectionsChange.emit('a-z')">
                {{ 'SORT_BY_A_Z' | translate }}
              </li>
              <li nz-menu-item (click)="sortCollectionsChange.emit('z-a')">
                {{ 'SORT_BY_Z_A' | translate }}
              </li>
              <li nz-menu-item (click)="sortCollectionsChange.emit('newest')">
                {{ 'SORT_BY_NEWEST' | translate }}
              </li>
              <li nz-menu-item (click)="sortCollectionsChange.emit('oldest')">
                {{ 'SORT_BY_OLDEST' | translate }}
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      <div class="query-collections__workspace-select-wrapper">
        <nz-form-label>{{
          'COLLECTIONS_WORKSPACES_FILTER' | translate
        }}</nz-form-label>
        <nz-form-control>
          <nz-select
            class="dialog-block"
            [ngModel]="workspaceId$ | async"
            (ngModelChange)="workspaceId$.next($event)"
            name="collection"
            nzPlaceHolder="Choose"
          >
            <nz-option
              nzValue=""
              [nzLabel]="'COLLECTIONS_WORKSPACES_SELECT_ALL' | translate"
            ></nz-option>
            @for (
              workspace of workspaces$ | async;
              track trackById($index, workspace)
            ) {
              <nz-option
                nzCustomContent
                [nzValue]="workspace.id"
                [nzLabel]="workspace.label"
              >
                @if (workspace.id !== 'local') {
                  <app-icon name="cloud" title="Remote"></app-icon>
                }
                @if (workspace.teamId) {
                  <app-icon name="users" title="Team"></app-icon>
                }
                {{ workspace.label }}
              </nz-option>
            }
          </nz-select>
        </nz-form-control>
      </div>
      <div class="query-collections__items-wrapper">
        <!-- TODO: Group by spaces -->
        @for (
          collectionTree of filteredCollectionTrees$ | async;
          track trackById($index, collectionTree)
        ) {
          <app-query-collection-item
            [collectionTree]="collectionTree"
            [loggedIn]="loggedIn"
            [queriesSortBy]="queriesSortBy"
            (selectQueryChange)="selectQueryChange.emit($event)"
            (deleteQueryChange)="deleteQueryChange.emit($event)"
            (deleteCollectionChange)="deleteCollectionChange.emit($event)"
            (editCollectionChange)="editCollectionChange.emit($event)"
            (exportCollectionChange)="exportCollectionChange.emit($event)"
            (syncCollectionChange)="syncCollectionChange.emit($event)"
            (sortCollectionQueriesChange)="sortCollectionQueriesChange.emit($event)"
            (showQueryRevisionsChange)="showQueryRevisionsChange.emit($event)"
            (copyQueryShareLinkChange)="copyQueryShareLinkChange.emit($event)"
          ></app-query-collection-item>
        }
        @if (!collectionTrees.length) {
          <div class="query-collections__empty">
            <div class="query-collections__empty-icon">
              <app-icon name="sidebar" size="40"></app-icon>
            </div>
            <div class="query-collections__empty-description">
              {{ 'COLLECTIONS_EMPTY_TEXT' | translate }}
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
