<app-dialog
  [showDialog]="showDialog"
  [heading]="'TEAMS_TEXT' | translate"
  [subheading]="'TEAMS_SUB_TEXT' | translate"
  [showFooter]="false"
  (toggleDialog)="toggleDialogChange.emit($event)"
>
  <div class="teams-wrapper">
    <ul nz-list [nzDataSource]="teams$ | async" [nzNoResult]="'No team'">
      @for (team of teams$ | async; track team) {
        <li nz-list-item nzNoFlex>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <a (click)="selectTeam(team.id)">
                <app-icon name="list"></app-icon>
                {{ 'TEAM_MEMBERS_TEXT' | translate }}
              </a>
            </nz-list-item-action>
            <nz-list-item-action>
              <a (click)="onEditTeam(team.id)">
                <app-icon name="edit"></app-icon>
                {{ 'EDIT_TEXT' | translate }}
              </a>
            </nz-list-item-action>
            <nz-list-item-action>
              <a (click)="onDeleteTeam(team.id)">
                <app-icon name="trash-2"></app-icon>
                {{ 'DELETE_TEXT' | translate }}
              </a>
            </nz-list-item-action>
          </ul>
          {{ team.name }}
        </li>
      }
    </ul>
    <div class="teams__list-actions">
      <button class="btn btn--full-width" (click)="onCreateTeam()">
        {{ 'CREATE_TEAM_BUTTON' | translate }}
      </button>
    </div>
  </div>
</app-dialog>

<app-dialog
  [showDialog]="showTeamForm"
  [heading]="(editTeamId ? 'UPDATE_TEAM_TEXT' : 'CREATE_TEAM_TEXT') | translate"
  [showFooter]="false"
  (toggleDialog)="showTeamForm = $event"
>
  <form [formGroup]="teamForm" (ngSubmit)="onSubmitTeamForm()">
    <div class="app-dialog-section">
      <input
        class="input"
        type="text"
        placeholder="Team name"
        name="teamName"
        formControlName="name"
      />
    </div>
    <div class="app-dialog-section">
      <textarea
        class="input"
        placeholder="Description"
        name="teamDescription"
        formControlName="description"
      ></textarea>
    </div>
    <div class="app-dialog-section">
      <button class="btn btn--primary" type="submit">
        {{ 'SAVE_BUTTON' | translate }}
      </button>
    </div>
  </form>
</app-dialog>

<app-dialog
  [showDialog]="!!(selectedTeamId$ | async)"
  [heading]="'TEAM_MEMBERS_TEXT' | translate"
  [showFooter]="false"
  (toggleDialog)="selectTeam()"
>
  <ul nz-list [nzDataSource]="membersOfSelectedTeam" [nzNoResult]="'No team member'">
    @for (member of membersOfSelectedTeam; track member) {
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a (click)="onEditTeamMember(member.userId)">
              <app-icon name="edit"></app-icon>
              {{ 'EDIT_TEXT' | translate }}
            </a>
          </nz-list-item-action>
        </ul>
        {{ member.user.firstName }}
      </li>
    }
  </ul>
  <button class="btn btn--full-width" (click)="onAddTeamMember()">
    {{ 'ADD_TEAM_MEMBER_TEXT' | translate }}
  </button>
</app-dialog>

<app-dialog
  [showDialog]="showMemberForm"
  [heading]="
    (editUserId ? 'UPDATE_TEAM_MEMBER_TEXT' : 'ADD_TEAM_MEMBER_TEXT') | translate
  "
  [showFooter]="false"
  (toggleDialog)="showMemberForm = $event"
>
  <nz-alert
    nzType="warning"
    nzMessage="The user must already exist to add them as a team member"
  ></nz-alert>
  <form [formGroup]="memberForm" (ngSubmit)="onSubmitMemberForm()">
    <div class="app-dialog-section">
      <input
        class="input"
        type="email"
        placeholder="Email address"
        name="email"
        formControlName="email"
      />
    </div>
    <div class="app-dialog-section">
      <button class="btn btn--primary" type="submit">
        {{ 'SAVE_BUTTON' | translate }}
      </button>
    </div>
  </form>
</app-dialog>
