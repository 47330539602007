<nz-modal
  [nzVisible]="showImportCurlDialog"
  (nzVisibleChange)="toggleDialogChange.next($event)"
  [nzCloseIcon]="'close-circle'"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="toggleDialogChange.next($event)"
>
  <ng-template #modalTitle>
    <div class="app-dialog-header">
      <div class="app-dialog-title">
        {{ 'IMPORT_CURL_TITLE_TEXT' | translate }}
      </div>
      {{ 'IMPORT_CURL_TITLE_SUBTEXT' | translate }}
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="app-dialog-body">
      <div class="form-group">
        <textarea
          class="dialog-textarea"
          aria-label="text input"
          cols="30"
          rows="10"
          [(ngModel)]="textAreaInput"
        ></textarea>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="app-dialog-footer">
      <button
        class="btn btn--primary right"
        track-id="close_import_from_curl_dialog"
        (click)="importInput()"
      >
        {{ 'IMPORT_BUTTON' | translate }}
      </button>
    </div>
  </ng-template>
</nz-modal>
