<form nz-form nzLayout="vertical" [formGroup]="basicForm">
  <nz-form-item>
    <nz-form-label nzRequired nzFor="username">Username</nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="The input is not a valid username!"
    >
      <app-x-input
        nz-input
        formControlName="username"
        id="username"
        class="input"
        autocomplete="off"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="password">Password</nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="The input is not a valid password!"
    >
      <app-x-input
        nz-input
        formControlName="password"
        id="password"
        class="input"
        type="password"
        autocomplete="off"
      />
    </nz-form-control>
  </nz-form-item>
</form>
