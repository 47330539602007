<mark [ngClass]="{ 'fancy-input-marker__invalid': !resolvedValue }">{{
  section.content
}}</mark>
@if (resolvedValue) {
  <div class="value-indicator__wrapper">
    .
    <div
      nz-tooltip
      [nzTooltipTitle]="resolvedValue"
      class="value-indicator"
    ></div>
  </div>
}
