<app-dialog
  [showDialog]="showDialog"
  [heading]="'SAVE_TO_COLLECTION_HEADER' | translate"
  [subheading]="'SAVE_TO_COLLCTION_SUBTEXT' | translate"
  (toggleDialog)="toggleDialogChange.emit($event)"
  (saveChange)="onSaveChange()"
>
  <div class="add-to-collection-wrapper">
    <div class="app-dialog-section">
      {{ 'SAVE_TO_COLLECTION_NAME_OF_QUERY' | translate }}
      <input
        type="text"
        class="input"
        [(ngModel)]="newCollectionQueryTitle"
        attr.aria-label="{{ 'SAVE_TO_COLLECTION_NAME_OF_QUERY' | translate }}"
        data-test-id="collection-query-name"
      />
    </div>
    <div class="app-dialog-section">
      <nz-form-label>{{
        'SAVE_TO_COLLECTION_SELECT_COLLECTION' | translate
      }}</nz-form-label>
      <nz-form-control>
        <nz-select
          class="dialog-block"
          [(ngModel)]="collectionId"
          name="collection"
          nzPlaceHolder="Choose"
        >
          @for (collection of collections; track trackById($index, collection)) {
            <nz-option
              [nzValue]="collection.id"
              [nzLabel]="collection.title"
            ></nz-option>
          }
          <nz-option
            nzValue="-1"
            [nzLabel]="
              'SAVE_TO_COLLECTION_OR_CREATE_NEW_COLLECTION_LABEL' | translate
            "
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </div>
    @if (isNewCollection()) {
      <div class="app-dialog-section">
        {{ 'SAVE_TO_COLLECTION_COLLECTION_NAME' | translate }}
        <input
          type="text"
          class="input"
          [(ngModel)]="newCollectionTitle"
          placeholder="Collection name"
          attr.aria-label="{{ 'SAVE_TO_COLLECTION_COLLECTION_NAME' | translate }}"
          data-test-id="new-collection-name"
        />
      </div>
      <div class="app-dialog-section">
        <nz-form-label> Save collection to </nz-form-label>
        <nz-form-control>
          <nz-tree-select
            [nzNodes]="collectionNodes"
            [nzNotFoundContent]="'No collections found'"
            nzShowSearch
            nzPlaceHolder="Select collection"
            [(ngModel)]="newCollectionParentCollectionId"
            (ngModelChange)="newCollectionParentCollectionIdChange.emit($event)"
          ></nz-tree-select>
        </nz-form-control>
      </div>
      <!-- if logged in, and not saving to a parent collection, then show workspaces option -->
      @if (loggedIn && newCollectionParentCollectionId === parentCollectionRootId) {
        <div class="app-dialog-section">
          <nz-form-label>{{
            'SAVE_TO_COLLECTION_SELECT_WORKSPACE' | translate
          }}</nz-form-label>
          <nz-form-control>
            <nz-select
              class="dialog-block"
              [(ngModel)]="workspaceId"
              name="workspace"
              nzPlaceHolder="Choose"
            >
              @for (workspace of workspaces; track trackById($index, workspace)) {
                <nz-option
                  [nzValue]="workspace.id"
                  [nzLabel]="workspace.label"
                ></nz-option>
              }
            </nz-select>
          </nz-form-control>
        </div>
      }
    }
  </div>
</app-dialog>
