<nz-tabset
  class="main-view-tabs query-result__tabs"
  [nzAnimated]="false"
  nzSize="small"
  [nzTabBarGutter]="16"
  [(nzSelectedIndex)]="selectedIndex"
>
  <nz-tab [nzTitle]="'QUERY_RESULT_TAB' | translate">
    <div class="query-result-container">
      @if (!queryResponses.length) {
        <div class="query-result__none">
          <div class="query-result__none-inner">
            <div class="query-result__none-subtext">
              {{ 'QUERY_RESULT_NO_RESULT_TEXT' | translate }}
            </div>
            <div class="query-result__none-art clearfix">
              <div class="left">
                <div class="query-result__none-art-button send"></div>
                <div class="query-result__none-art-button"></div>
                <div class="query-result__none-art-button"></div>
                <div class="query-result__none-art-button"></div>
              </div>
              <div class="right">
                <div class="query-result__none-art-button"></div>
              </div>
            </div>
            <app-tips
              [activeWindowId]="activeWindowId"
              [windowId]="windowId"
              [loading]="isRunning"
            />

            @if (isRunning) {
              <button
                class="btn btn--small btn--light btn--destructive"
                (click)="cancelRequestChange.emit()"
              >
                <app-icon name="x"></app-icon>
                {{ 'CANCEL_TEXT' | translate }}
              </button>
            }
          </div>
        </div>
      } @else {
        <div class="query-result__top">
          <div class="query-result__top-section">
            <span
              class="response-status-code response-stats__item"
              [attr.data-status-code]="responseStatus"
            >
              {{ responseStatus }} {{ responseStatusText }}
            </span>
            <span class="response-time response-stats__item">
              <app-icon name="clock"></app-icon> {{ responseTime }}ms
            </span>
            @if (isSubscribed && isRunning) {
              <span class="query-result__uri">{{ subscriptionUrl }}</span>
            }
          </div>
          <div class="query-result__top-section">
            @if (isRunning && queryResponses.length > 1) {
              <button
                track-id="autoscroll"
                class="btn btn--small btn--light"
                [ngClass]="{ 'btn--primary': autoscrollResponseList }"
                (click)="autoscrollResponseListChange.emit()"
              >
                <app-icon name="arrow-down-narrow-wide"></app-icon>
                {{ 'SUBSCRIPTIONS_AUTOSCROLL_TEXT' | translate }}
              </button>
            }
            @if (!isRunning && queryResponses.length) {
              <button
                track-id="clear_result"
                class="btn btn--small"
                (click)="clearResultChange.emit()"
              >
                <app-icon name="trash-2"></app-icon>
                {{ 'QUERY_RESULT_CLEAR_RESULT_BUTTON' | translate }}
              </button>
            }

            @if (isRunning) {
              <button
                class="btn btn--small btn--light btn--destructive"
                (click)="cancelRequestChange.emit()"
              >
                <app-icon name="x"></app-icon>
                {{ 'CANCEL_TEXT' | translate }}
              </button>
            }
          </div>
        </div>
        <div class="query-result__list" #queryResultList>
          @for (item of queryResponses; track $index) {
            <div class="query-result__list-item" #queryResultItem>
              <app-codemirror
                [ngModel]="item.content"
                [fullHeight]="true"
                [extensions]="editorExtensions"
              ></app-codemirror>
              <div class="query-result__list-item-info">
                {{ item.timestamp | date: 'mediumTime' }}
              </div>
              <div class="query-result__list-item-actions">
                <button
                  track-id="download_result"
                  class="btn btn--small"
                  (click)="downloadResultChange.emit(item.content)"
                >
                  <app-icon name="download"></app-icon>
                  {{ 'QUERY_RESULT_DOWNLOAD_RESULT_BUTTON' | translate }}
                </button>
                @for (uiAction of uiActions; track uiAction) {
                  <button
                    class="btn btn--small"
                    (click)="uiActionExecuteChange.emit(uiAction)"
                  >
                    {{ uiAction.title }}
                  </button>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  </nz-tab>
  @if (requestScriptLogs.length) {
    <nz-tab
      [nzTitle]="'REQUEST_SCRIPT_LOGS_TAB' | translate"
      [nzDisabled]="!requestScriptLogs.length"
    >
      <div class="request-script-logs">
        <div class="request-script-logs__list">
          @for (item of requestScriptLogs; track item) {
            <div class="request-script-logs__line">
              <div class="request-script-logs__line-time">
                {{ item.time | date: 'mediumTime' }}
              </div>
              <!-- <div class="request-script-logs__line-source">{{ item.source }}</div> -->
              <div class="request-script-logs__line-text">{{ item.text }}</div>
            </div>
          }
        </div>
      </div>
    </nz-tab>
  }
  <nz-tab [nzTitle]="'RESPONSE_HEADERS_TAB' | translate">
    <div class="query-result-container">
      <div class="response-headers__container">
        <nz-table
          #smallTable
          nzSize="small"
          nzNoResult=""
          [nzShowPagination]="false"
          [nzTemplateMode]="true"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            @for (headerKey of responseHeaders | keys; track headerKey) {
              <tr>
                <td>{{ headerKey }}</td>
                <td>{{ responseHeaders[headerKey] }}</td>
              </tr>
            }
          </tbody>
        </nz-table>
        @if (!isElectron) {
          <div class="response-headers__notice">
            {{ 'RESPONSE_HEADERS_MORE_IN_APPS' | translate }}
          </div>
        }
      </div>
    </div>
  </nz-tab>
</nz-tabset>
<div class="query-result__bottom-pane">
  <nav class="query-result__bottom-pane__title-wrapper">
    @for (panel of bottomPanels; track trackById($index, panel)) {
      <div
        (click)="togglePanelActive(panel)"
        attr.aria-label="{{ panel.title }}"
        [ngClass]="{ 'query-result__bottom-pane__title--active': panel.isActive }"
        class="query-result__bottom-pane__title"
      >
        {{ panel.title }}
      </div>
    }
  </nav>
  <div class="query-result__bottom-pane__panels">
    @for (panel of bottomPanels; track trackById($index, panel)) {
      @if (panel.isActive) {
        <div class="query-result__bottom-pane__panel">
          <app-element-wrapper
            [element]="panel.element"
            [windowId]="windowId"
            [activeWindowId]="activeWindowId"
          ></app-element-wrapper>
        </div>
      }
    }
  </div>
</div>
