@if (item) {
  <nz-form-label>{{ item.description }}</nz-form-label>
  @if (data) {
    @for (subdata of data; track trackByIndex(index, subdata); let index = $index) {
      <div class="ui-form__list-wrapper">
        @if (isJsonSchema(item.items)) {
          <app-schema-form-item
            [item]="getSchemaFormPropertyForListItem(index, item.items)"
            [(data)]="data[index]"
            (dataChange)="dataChange.next(data)"
          ></app-schema-form-item>
        }
        <span class="ui-form__list-item-close" (click)="removeField(index)"
          >&#215; {{ 'SCHEMA_FORM_LIST_REMOVE' | translate }}</span
        >
      </div>
    }
  }
  <nz-form-item>
    <nz-form-control>
      <button class="btn" (click)="addField()">
        &#43;
        {{ 'SCHEMA_FORM_LIST_ADD' | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>
}
