@if (item) {
  <nz-form-item class="ui-form__item">
    @switch (item.type) {
      @case ('number') {
        <nz-form-label>{{ item.description }}</nz-form-label>
        <nz-form-control>
          <nz-input-number
            [(ngModel)]="data"
            [name]="item.key"
            (ngModelChange)="onInput($event, item)"
          ></nz-input-number>
        </nz-form-control>
      }
      @case ('boolean') {
        <nz-form-label>{{ item.description }}</nz-form-label>
        <nz-form-control>
          <nz-switch
            [(ngModel)]="data"
            [name]="item.key"
            (ngModelChange)="onInput($event, item)"
          ></nz-switch>
        </nz-form-control>
      }
      @case ('string') {
        @if (isStringOrUndefined(data)) {
          <app-schema-form-item-input
            [item]="item"
            [(data)]="data"
            (dataChange)="onInput($event, item)"
          ></app-schema-form-item-input>
        }
      }
      @case ('array') {
        @if (isArrayOrUndefined(data)) {
          <app-schema-form-item-list
            [item]="item"
            [(data)]="data"
            (dataChange)="onInput($event, item)"
          ></app-schema-form-item-list>
        }
      }
      @default {
        @if (item.ref) {
          @switch (item.refType) {
            @case ('enum.string') {
              <nz-form-label>{{ item.description }}</nz-form-label>
              <nz-form-control>
                <nz-select
                  [(ngModel)]="data"
                  name="item.key"
                  nzPlaceHolder="Choose"
                  (ngModelChange)="onInput($event, item)"
                  >
                  @for (option of getSelectOptions(item); track option) {
                    <nz-option
                      [nzValue]="option.value"
                      [nzLabel]="option.label"
                    ></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            }
          }
        }
      }
    }
  </nz-form-item>
}
