<nz-modal
  [nzVisible]="showEditCollectionDialog"
  (nzVisibleChange)="toggleDialogChange.next($event)"
  [nzCloseIcon]="'close-circle'"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzWidth]="800"
  (nzOnCancel)="toggleDialogChange.next($event)"
>
  <ng-template #modalTitle>
    <div class="app-dialog-header">
      <div class="app-dialog-title">
        {{ 'EDIT_COLLECTION_TITLE_TEXT' | translate }}
      </div>
      {{ 'EDIT_COLLECTION_TITLE_SUBTEXT' | translate }}
    </div>
  </ng-template>

  <ng-template #modalContent>
    @if (collection) {
      <div class="app-dialog-body">
        <div class="app-dialog-section">
          <div class="form-group">
            {{ 'COLLECTION_TITLE_LABEL' | translate }}
            <input type="text" class="input" [(ngModel)]="title" />
          </div>
        </div>
        <div class="app-dialog-section">
          <nz-tabset [nzAnimated]="false" nzSize="small" [nzTabBarGutter]="16">
            <nz-tab [nzTitle]="'PRE_REQUEST_TAB' | translate">
              <app-pre-request-editor
                [preRequest]="preRequest"
                (preRequestScriptChange)="preRequest.script = $event"
                (preRequestEnabledChange)="preRequest.enabled = $event"
              ></app-pre-request-editor>
            </nz-tab>
            <nz-tab [nzTitle]="'POST_REQUEST_TAB' | translate">
              <app-post-request-editor
                [postRequest]="postRequest"
                (postRequestScriptChange)="postRequest.script = $event"
                (postRequestEnabledChange)="postRequest.enabled = $event"
              ></app-post-request-editor>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    }
  </ng-template>

  <ng-template #modalFooter>
    <div class="app-dialog-footer">
      <button
        class="btn btn--primary right"
        track-id=""
        (click)="updateCollection()"
      >
        {{ 'SAVE_BUTTON' | translate }}
      </button>
    </div>
  </ng-template>
</nz-modal>
