<nz-modal
  [nzVisible]="showSettingsDialog"
  (nzVisibleChange)="toggleDialogChange.next($event)"
  [nzCloseIcon]="'close-circle'"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzWidth]="800"
  (nzOnCancel)="toggleDialogChange.next($event)"
>
  <ng-template #modalTitle>
    <div class="app-dialog-header">
      <div class="app-dialog-title">{{ 'SETTINGS_TEXT' | translate }}</div>
      {{ 'SETTINGS_SUB_TEXT' | translate }}
    </div>
  </ng-template>

  <ng-template #modalContent>
    <div class="app-dialog-body">
      <small
        ><a
          href="#"
          (click)="$event.preventDefault(); $event.stopPropagation(); onToggleView()"
          >{{ 'SETTINGS_TOGGLE_ADVANCED_MODE' | translate }}</a
        ></small
      >
      @if (showForm) {
        <div class="app-dialog-section">
          <app-schema-form
            [schema]="settingsSchema"
            [data]="localSettings"
            (dataChange)="onFormDataChange($event)"
          ></app-schema-form>
        </div>
      }

      @if (!showForm) {
        <div class="app-dialog-section">
          <app-codemirror
            #editor
            class="settings-editor-textarea"
            [fullHeight]="true"
            [extensions]="editorExtensions"
            [ngModel]="jsonSettings"
            (ngModelChange)="onSettingsChange($event)"
          ></app-codemirror>
          <small>{{ 'SETTINGS_SHOW_EDITOR_HINT' | translate }}</small>
          <small
            ><a
              target="_blank"
              href="http://altairgraphql.dev/docs/features/settings-pane"
              rel="noopener"
              >Click here for available settings options</a
            ></small
          >
        </div>
      }

      <div class="app-dialog-section">
        <nz-collapse>
          <nz-collapse-panel [nzHeader]="'SETTINGS_KEYBOARD_SHORTCUTS' | translate">
            <div>
              <nz-collapse [nzBordered]="false">
                @for (
                  category of shortcutCategories;
                  track trackByIndex($index, category)
                ) {
                  <nz-collapse-panel [nzHeader]="category.title">
                    <nz-list
                      [nzDataSource]="category.shortcuts"
                      nzBordered
                      [nzRenderItem]="shortcutItem"
                    >
                      <ng-template #shortcutItem let-shortcut>
                        <nz-list-item>
                          <span class="settings-shortcut-description">
                            {{ shortcut.description }}
                          </span>
                          @for (
                            key of shortcut.keys;
                            track trackByIndex($index, key)
                          ) {
                            <span class="settings-shortcut-key">{{ key }}</span>
                          }
                        </nz-list-item>
                      </ng-template>
                    </nz-list>
                  </nz-collapse-panel>
                }
              </nz-collapse>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
        <div>
          <small
            ><a
              href="#"
              class="settings__reset-link"
              (click)="onResetApplicationData($event)"
              track-id="reset_application_data"
              >{{ 'SETTINGS_RESET_APPLICATION_DATA' | translate }}</a
            ></small
          >
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="app-dialog-footer">
      <div class="left text-align-left">
        <label>{{ 'SETTINGS_VERSION_TEXT' | translate }} {{ appVersion }}</label>
        <div>
          <small
            >{{ 'SETTINGS_HELP_WITH_TRANSLATIONS_TEXT' | translate }}
            <a
              href="https://altair-gql-translate.surge.sh/"
              target="_blank"
              track-id="go_to_translate_link"
              rel="noopener"
              >{{ 'CLICK_HERE_TEXT' | translate }}</a
            ></small
          >
        </div>
      </div>
      <button
        track-id="save_settings"
        class="btn btn--primary right"
        (click)="saveSettings()"
      >
        {{ 'SAVE_BUTTON' | translate }}
      </button>
    </div>
  </ng-template>
</nz-modal>
